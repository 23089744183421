<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <vuexy-logo />
      <h2 class="brand-text text-primary ml-1">
        Dental SIGO
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Bem vindo ao Dental SIGO! 🔐
        </h2>
        <div
          v-if="!getUserData().email_verified_at"
        >
          <p
            v-if="message"
            class="mb-2"
          >
            {{ message }}
          </p>
          <p
            v-else
            class="mb-2"
          >
            Um email foi enviado para o endereço utilizado em seu cadastro com um link que deve ser acessado para completar sua inscrição e ativar sua conta.
          </p>
          <p class="mb-2">
            Verifique seu email.
          </p>

          <b-button
            v-if="!message"
            variant="primary"
            class="mb-1 btn-sm-block"
            @click="resendEmailVerify()"
          >Caso não tenha recebido um email do Dental SIGO, clique aqui para solicitar o reenvio.</b-button>
        </div>
        <div
          v-else
        >
          <h2 class="mb-1">
            Sua conta já está ativa. Clique no botão abaixo e faça login.
          </h2>
        </div>

        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          @click="loginRoute()"
        >Voltar a tela de Login.</b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Not authorized page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg, BButton } from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import { initialAbility } from '@/libs/acl/config'
import axios from '@axios'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BLink, BImg, BButton, VuexyLogo,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      message: null,
      getUserData,
    }
  },

  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },

  mounted() {
    if (this.$route.query.verified) {
      this.loginRoute()
    }
  },

  methods: {
    loginRoute() {
      useJwt.logout()
        .then(() => {
          // Remove userData from localStorage
          // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
          localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
          localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

          // Remove userData from localStorage
          localStorage.removeItem('userData')

          // Reset ability
          this.$ability.update(initialAbility)

          // Redirect to login page
          this.$router.push({ name: 'auth-login' })
        })
        .catch(error => {
          this.$refs.loginForm.setErrors(error.response.data.error)
        })
    },
    async resendEmailVerify() {
      try {
        const resp = await axios.post(`${process.env.VUE_APP_API_URL}/email/verification-notification`)
        this.message = resp.data.data.message
      } catch (error) {
        //
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
